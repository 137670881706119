import React, {useEffect, useState} from "react";
import { AutoComplete } from 'primereact/autocomplete';
import fetch from '../../../config/service';
import config from "../../../config/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";

const DoAutoCompleteField = (props) => {
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [isValidate,setIsValidate] = useState(false);
    const [noData,setNoData] = useState(false);
    const [errorMessage,setErrorMessage] = useState('');
    const [disable, setDisable] = props.item?.hasDependencyField ? useState(false) : useState(false); 

    let message;
    const id = props.id ? props.id : props.name;
    const label = props.label ? props.label : '';
    const name = props.name;
    const type = props.type ? props.type : 'text';
    let field = props.field;
    const placeholder = props.placeholder;
    let parentField = props.watch && props.item?.hasDependencyField ? props.watch(props.item.hasDependencyField) : '';

    useEffect(() => {
        if(props.item?.hasDependencyField){
            if(parentField){
                // setDisable(false);
                setErrorMessage('')
            } else {
                // setDisable(true);
            }
            props.setValue(props.field.name,'');
        }
    }, [parentField])
    
    const onSelectRecord = (e)=> {
        setNoData(false);
        setIsValidate(false);
        setErrorMessage('');
        if (props.input) {
            const { name, onChange } = this.props.input;
            onChange(e.value);
            this.props.handleAutoCompleteData(e.value, name)
            if (e && e.value) {
                this.removeDuplicates(e.value, name);
            }
        }

    }

    const getAllSuggestions = (event, type) => {
        let url;
        setIsValidate(false);
        setNoData(false);
        setErrorMessage('');
        let filterCriteria = {
            limit: 30,
            page: 1,
            sortfield: "created",
            direction: "desc",
            criteria: []
        };

        if(props.item?.hasDependencyField){
            if(props.getValues(props.item.hasDependencyField) && props.getValues(props.item.hasDependencyField)["_id"]){
                filterCriteria['criteria'].push({
                    "key":props.item.fieldForKey,
                    "value":props.getValues(props.item.hasDependencyField)["_id"],
                    "type":"eq"
                })
            } else {
                setNoData(true);
                setFilteredSuggestions([]);
                setErrorMessage('Please select the ' + props.item.hasDependencyField[0].toUpperCase() +  props.item.hasDependencyField.slice(1));
                return
            }
        }
       
        if (event && event.query) {
            filterCriteria['criteria'].push({
                "key": props.searchField,
                "value": event.query,
                "type": "regexOr"
            });
        };

        let apiUrl = props.searchApi?.toLowerCase();
        url = `${apiUrl}?searchFrom=autoComplete&filter=${JSON.stringify(filterCriteria)}`;
        return fetch('GET', url)
            .then((response) => {
                if (response) {
                    let dropdownData = [];
                    if (response?.listUsers){
                        dropdownData = response['listUsers'];
                    }
                    if (response[apiUrl] && response[apiUrl].length && response[apiUrl].length > 0) {
                        dropdownData = response[apiUrl];
                    }
                    if (dropdownData && dropdownData.length == 0) {                       
                        setFilteredSuggestions([]);
                        setNoData(true);
                    } else {
                        setSuggestions(dropdownData);
                    }
                }
            }).catch((err) => {
                return err;
            });
    }

   const setSuggestions = async (dropdownData) => {
        if (props.input && props.input.value) {
            if (props.input.value.length > 0) {
                let values = props.input.value;
                if (Array.isArray(values)) {
                    values.forEach(element => {
                        let field = '_id';
                        dropdownData = dropdownData.filter((item) => item[field] !== element[field]);
                    });
                }
            }
        }
        setFilteredSuggestions(dropdownData);
    }

    if(props.fieldState.invalid){
        message = props.errors[props.name]?.message;
        if(props.errors[props.name]?.message.includes("must be a `object` type")) {
            message = "Select a valid option";

        }
    }

    let markRequired = () => {
        return (
          <FontAwesomeIcon
            color='red'
            icon={faAsterisk}
            style={{ width: '7px',marginBottom:"5px",marginLeft:"2px" }}
          />
        );
    };

    let onSelect = (e) => {
        if (!props.getValues(props.item?.name)) {
            props.setValue(props.item.name, e.value)
        }
    }

    return (
        <div className="flex flex-column">

            <div className="flex align-center"> {/* Added a wrapper div with flex class */}
                <label htmlFor={id} className="text-capitalize">{label}</label>
                {props.markReq && props.markReq === true && markRequired()}
            </div>
            <AutoComplete
                inputId={field.name}
                value={field.value}
                onChange={field.onChange}
                // inputRef={field.ref}
                panelStyle={{ width: '100px' }}
                disabled={disable}
                suggestions={filteredSuggestions}
                completeMethod={getAllSuggestions}
                placeholder={field.value == undefined || field.value.length == 0 ? placeholder : ''}
                field={props.searchField}
                dropdown={true}
                onSelect={onSelect}
                multiple={props.multiple}
                selectionLimit={props.selectionLimit ? props.selectionLimit : config.selectionLimit}
                showEmptyMessage={true}
            />

            <small className="text-danger ">{props.fieldState.invalid ? message ? message : props.errors[props.name]?.message : ''}</small>
            {errorMessage && <small className="text-danger">{errorMessage}</small>}
            {noData && !props.fieldState.invalid && <small className="text-danger">{'No Data Found'}</small>}
        </div>
    )

}

export default DoAutoCompleteField;