import React from "react";
import { InputText } from 'primereact/inputtext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";

const DoInputField = (props) => {
    const id = props.id ? props.id : props.name;
    const label = props.label ? props.label : '';
    const name = props.name;
    const type = props.type || 'text'; 
    let field = props.field;
    const placeholder = props.placeholder;
    const style = props.style || null;

    let markRequired = () => {
        return (
          <FontAwesomeIcon
            color='red'
            icon={faAsterisk}
            style={{ width: '7px',marginBottom:"5px",marginLeft:"2px" }}
          />
        );
      };

  const handleChange = (e) => {
    const value = e.target.value;
    if (props.field?.onChange) {
      props.field.onChange(value);
    }
    if (props.onChange) {
      props.onChange(e);
    }
  };

  return (
    <div className="flex flex-column">
      <div className="flex align-center"> {/* Added a wrapper div with flex class */}
        <label htmlFor={id} className="text-capitalize">{label}</label>
        {props.markReq && props.markReq === true && markRequired()}
      </div>
      <InputText
        type={type}
        value={props.value || props.field?.value || ''}
        id={id}
        name={name}
        placeholder={placeholder}
        style={style}
        onChange={handleChange}
        onBlur={props.onBlur}
        onKeyDown={props.onKeyDown}
      />

      <small className="text-danger">{props.fieldState?.invalid ? props.errors[props.name]?.message : ''}</small>
    </div>
  )

}

export default DoInputField;