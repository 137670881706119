import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Loader from '../App/Loader';
import fetchMethodRequest from '../../config/service';
import { Chart } from 'primereact/chart';
import { Controller, useForm } from 'react-hook-form';
import DoDateField from '../Form/Fields/DoDateField';
import DoAutoCompleteField from '../Form/Fields/DoAutoCompleteField';
import config from '../../config/config';
import dateFormats from '../UI/FormatDate/formatDate';
import moment from 'moment';

const Dashboard = () => {
  const { t } = useTranslation('common');

  const [isLoading, setIsLoading] = useState(false);
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [windows, setWindows] = useState({});

  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    control,
  } = useForm();

  useEffect(() => {
    getUsers();
  }, [])

  const fetchDashboardData = (values) => {
    let user = "";
    if (values) {
      values.date = dateFormats.formatDate(values.date, config.dateYearMonthFormat);
      user = values.userName.userName;
    }

    fetchMethodRequest('POST', `userActivities/getUserActivities?userName=${encodeURIComponent(user)}&date=${encodeURIComponent(values?.date)}`)
      .then(async (response) => {
        if (response) {
          initializeChart(response.result);
        }
      }).catch((err) => {
        return err;
      });
  };

  const getUsers = () => {
    let filterCriteria = { "limit": 30, "page": 1, "sortfield": "created", "direction": "desc", "criteria": [] };
    fetchMethodRequest('GET', `useractivities/listusers?searchFrom=autoComplete&filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response.respCode) {
        setValue('date', new Date());
        setValue('userName', response.listUsers[0]);
        fetchDashboardData({
          date: new Date(), userName: response.listUsers[0]
        });
      }
    })
  }

  const initializeChart = (res) => {

    if (!Array.isArray(res) || res.length === 0) {
      return;
    }

    const labels = res.map((item) => moment.utc(item['onDate']).local().format('HH:mm'));
    const dataPoints = res.map(() => 1);
    const backgroundColors = res.map((item) => item.colorCode);
    const borderColors = res.map((item) => item.colorCode);

    // Chart.js data and options
    const data = {
      labels,
      datasets: [
        {
          label: '',
          data: dataPoints,
          backgroundColor: backgroundColors,
          borderColor: borderColors,
          borderWidth: 1,
        },
      ],
    };

    const createTitleColorMap = (dataArray) =>
      dataArray
        .filter((item) => item.windowName !== 'Inactive')
        .reduce((acc, item) => {
          acc[item.windowName] = item.colorCode;
          return acc;
        }, {});

    const colorIndic = createTitleColorMap(res);

    const generateLegendLabels = (chart, colorMap) =>
      Object.entries(colorMap).map(([windowName, colorCode]) => ({
        text: windowName,
        fillStyle: colorCode,
        strokeStyle: colorCode,
        lineWidth: 1,
        hidden: false,
      }));

    const options = {
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            display: false,
          },
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              const index = tooltipItem.dataIndex;
              const item = res[index];
              return `${item.windowName}: ${item.windowTitle}`;
            },
          },
        },
        legend: {
          display: true,
          labels: {
            generateLabels: (chart) => generateLegendLabels(chart, colorIndic),
          },
        }
      },
    };

    setChartData(data);
    setChartOptions(options);
  };


  const submit = (values) => {
    fetchDashboardData(values);
  }


  return (
    <Container className="dashboard ml-5" style={{ width: '98%' }}>

      <Col className='col-12'>
        <Card>
          <CardBody>
            <Loader loader={isLoading} />
            <Row>
              <Col md={12}>
                <h4>
                  <span className='postionRelative pt-2'>
                    <a className='heading'>
                      DashBoard
                    </a>
                  </span>
                </h4>
              </Col>
            </Row>

            <Row>
              <form className="mt-4" onSubmit={handleSubmit(submit)}>
                <div className='d-flex align-items-center gap-5'>
                  <div className='d-flex align-items-center gap-2'>
                    <label className="text-capitalize fs-6">Select User</label>
                    <Controller
                      name={"userName"}
                      control={control}
                      render={({ field, fieldState }) => (
                        <DoAutoCompleteField
                          input={field}
                          id={field.id}
                          name={field.name}
                          field={field}
                          multiple={false}
                          fieldState={fieldState}
                          errors={errors}
                          searchApi={"userActivities/listUsers"}
                          searchField={'userName'}
                          placeholder={"User"}
                          // label={"User"}
                          getValues={getValues}
                          setValue={setValue}
                        />)}
                    />
                  </div>

                  <div className='d-flex align-items-center gap-2'>
                    <label className="text-capitalize fs-6">Select Date</label>
                    <Controller
                      name={"date"}
                      control={control}
                      render={({ field, fieldState }) => (
                        <DoDateField
                          input={field}
                          id={field.id}
                          name={field.name}
                          field={field}
                          fieldState={fieldState}
                          errors={errors}
                          placeholder="Date"
                          value={new Date()}
                        />)}
                    />
                  </div>

                  <Button className="ml-auto mb-1 py-2" color="primary" >Show Chart</Button>
                </div>
              </form>
            </Row>

            <Row>
              <Chart type="bar" data={chartData} options={chartOptions} height='135' />
            </Row>
          </CardBody>
        </Card>
      </Col>

    </Container>
  );
};

Dashboard.propTypes = {
  t: PropTypes.func.isRequired,
};

export default Dashboard;