import React, { useEffect, useRef, useState } from 'react';
import fetchMethodRequest from '../../config/service';
// Toaster message
import showToasterMessage from '../UI/ToasterMessage/toasterMessage';
// Loader
import Loader from '../App/Loader';
import DoInputField from '../Form/Fields/DoInputField';
import DoRadioButtons from '../Form/Fields/DoRadioButtons';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from 'react-hook-form';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SketchPicker } from 'react-color'
import { OverlayPanel } from 'primereact/overlaypanel';
import ConfirmationModal from '../../containers/Cruds/CommonModals/ConfirmationModal';

const normalizePhone = (value) => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`
}
const SettingsForm = (props) => {

  const op7 = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [schema, setSchema] = useState({});
  const [options, setOptions] = useState(
    [
      { label: 'ddMMYYY', value: 'DD/MM/YYYY' },
      { label: 'MMDDYYY', value: 'MM/DD/YYYY' },
      { label: 'MMM DD YYYY', value: 'MMM DD YYYY' },
      { label: 'DD MMM YYYY', value: 'DD MMM YYYY' },
      { label: 'MM-DD-YYYY', value: 'MM-DD-YYYY' },
      { label: 'DD-MM-YYYY', value: 'DD-MM-YYYY' },
      { label: 'MM/DD/YYYY HH:mm A', value: 'MM/DD/YYYY HH:mm A' },
      { label: 'MMM DD YYYY HH:mm A', value: 'MMM DD YYYY HH:mm A' },
      { label: 'hh:mm A, MM-DD-YYYY', value: 'hh:mm A, MM-DD-YYYY' },
      { label: 'MM/DD/YYYY HH:mm', value: 'MM/DD/YYYY HH:mm' },
      { label: 'YYYY-MM-DD HH:mm:ss', value: 'YYYY-MM-DD HH:mm:ss' },
      { label: 'YYYY-MM-DD[T]HH:mm:ss.SSS', value: 'YYYY-MM-DD[T]HH:mm:ss.SSS' },
      { label: 'YYYY-MM-DD[T]00:00:00Z', value: 'YYYY-MM-DD[T]00:00:00Z' },
      { label: 'MMM YYYY', value: 'MMM YYYY' },
      { label: 'MMM  DD, YYYY', value: 'MMM  DD, YYYY' },
    ]
  );
  const VerificationOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ]
  const emailSorceOptions = [
    { label: 'Node Mailer', value: 'nodeMailer' },
    { label: 'Send Grid', value: 'sendgrid' },
  ]
  const enableMailOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ]
  const disableLoginOpt = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ]
  const [displayInputField, setDisplayInputField] = useState(false);
  const [windows, setWindows] = useState({});
  const [newWindow, setNewWindow] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('rgba(180, 156, 156, 1)');
  const [currentColor, setCurrentColor] = useState('');
  const [selectedWindow, setSelectedWindow] = useState('');
  const [editingWindow, setEditingWindow] = useState(null);
  const [editedValue, setEditedValue] = useState('');
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);
  const [deleteWindow, setDeleteWindow] = useState('');

  useEffect(() => {
    getSettingsData();
  }, [])

  let {
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    formState: { errors },
    control,
  } = useForm();

  const watchEmailSourceType = watch('emailSourceType', 'sendGrid');


  const getSettingsData = () => {
    setIsLoading(true);
    let filterCriteria = {};
    filterCriteria['criteria'] = [];
    fetchMethodRequest('GET', `settings?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response.respCode && response.settings && response.settings[0]) {
        setFormValues({ ...response.settings[0] });
        delete response.settings[0].windowNameColors["Inactive"];
        setWindows({ ...response.settings[0].windowNameColors });
      }
    })
  }

  const setFormValues = (settings) => {
    if (settings.Admin && typeof settings.Admin === 'object') {
      setValue('GoogleClientId', settings.Admin.GoogleClientId);
      setValue('GooglesecretKey', settings.Admin.GooglesecretKey);
    }
    setValue('sendGridApiKey', settings.sendGridApiKey);
    setValue('sendGridEmail', settings.sendGridEmail);
    setValue('emailSourceType', settings.emailSourceType);
    setValue('enableMails', settings.enableMails);
    setValue('disableMultipleLogin', settings.disableMultipleLogin);
    setValue('nodeMailerHost', settings.nodeMailerHost);
    setValue('nodeMailerPass', settings.nodeMailerPass);
    setValue('nodeMailerUser', settings.nodeMailerUser);
    setValue('expireTokenTimeInMin', settings.expireTokenTimeInMin);
    setValue('adminExpireTokenTimeInMin', settings.adminExpireTokenTimeInMin);
    setValue('isTwoFactorAuthentication', settings.isTwoFactorAuthentication);
    setValue('otpExpiryTimeInMin', settings.otpExpiryTimeInMin);
    setValue('_id', settings._id);
    setValue('captureTime', settings.captureTime / (60 * 1000));
    setIsLoading(false);
  }

  const onUpdate = (data) => {
    let formData = { ...data };
    formData.Admin = {};
    formData.Admin.GoogleClientId = data.GoogleClientId;
    formData.Admin.GooglesecretKey = data.GooglesecretKey;
    if (data.emailSourceType === 'nodeMailer') {
      delete formData.sendGridApiKey;
    } else {
      delete formData.nodeMailerHost;
      delete formData.nodeMailerPass;
      delete formData.nodeMailerUser;
    }
    delete formData.GoogleClientId;
    delete formData.GooglesecretKey;

    formData.windowNameColors = Object.assign({}, windows);
    formData.windowNameColors['Inactive'] = 'rgba(255, 255, 255, 1)';
    if (formData.captureTime) {
      formData.captureTime = formData.captureTime * 60 * 1000;
    }
    saveDataToServer(formData);
  }

  //send data to server
  const saveDataToServer = (formValues) => {
    setIsLoading(true);
    if (formValues) {
      let method, apiUrl;
      apiUrl = `settings/${formValues._id}`
      fetchMethodRequest('PUT', apiUrl, formValues)
        .then((response) => {
          setIsLoading(false);
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, 'success');
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, 'error');
          }

        })
    }
  }

  const apple = () => {
    console.log('I am refreshed')
  }

  //Color changes updates
  const openColorPicker = (e, windowName) => {
    setSelectedWindow(windowName);
    setCurrentColor(windows[windowName]);
    op7.current.toggle(e);
  };

  const onColorChange = (color) => {
    const newColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
    setCurrentColor(newColor);

    if (selectedWindow) {
      setWindows((prev) => ({
        ...prev,
        [selectedWindow]: newColor
      }));
    }
    setBackgroundColor(newColor);
  };

  //Adding and updating titles
  const updateWindows = (newName, oldName = null) => {
    if (!newName.trim()) return;

    setWindows((prevWindows) => {
      const updatedWindows = { ...prevWindows };

      //Updating an existing window
      if (oldName && oldName !== newName) {
        updatedWindows[newName] = updatedWindows[oldName];
        delete updatedWindows[oldName];
      } else if (!updatedWindows[newName]) {
        //Adding a new window
        updatedWindows[newName] = backgroundColor;
      }

      return updatedWindows;
    });

    setValue('newWindow', '');
    setNewWindow('');
    setEditedValue('');
    setEditingWindow(null);
    setBackgroundColor('rgba(180, 156, 156, 1)');
  };

  const handleEditClick = (windowName) => {
    setEditingWindow(windowName);
    setEditedValue(windowName);
  };

  const handleSave = (oldName) => {
    if (editedValue.trim() !== '' && editedValue !== oldName) {
      updateWindows(editedValue, oldName);
    }
    setEditingWindow(null);
  };

  const handleKeyDown = (e, oldName) => {
    if (e.key === 'Enter') {
      handleSave(oldName);
    } else if (e.key === 'Escape') {
      setEditingWindow(null);
    }
  };

  //Required Functions for deleting window titles
  const closeModal = () => {
    setIsOpenConfirmationModal(false);
  }

  const onConfirmation = () => {
    setWindows((prevWindows) => {
      const updatedWindows = { ...prevWindows };
      delete updatedWindows[deleteWindow];
      return updatedWindows;
    });
    setIsOpenConfirmationModal(false);
  }


  return (
    <div style={{ overflowX: 'hidden' }} onLoad={apple}>
      <Loader loader={isLoading} />
      <form onSubmit={handleSubmit(onUpdate)}>
        <div className='px-5 py-2 settings-content'>

          <Card title='Mail Settings'>
            <div className='row row-col-12'>
              <div className='d-flex col-6'>
                <div className='d-flex col-md-6'>
                  <Controller
                    name={'emailSourceType'}
                    control={control}
                    render={({ field, fieldState }) => (
                      <DoRadioButtons
                        input={field}
                        id={field.id}
                        name={field.name}
                        field={field}
                        options={emailSorceOptions}
                        fieldState={fieldState}
                        errors={errors}
                        label={'Email Source Type'}
                      />)}
                  />
                </div>

                <div className='d-flex col-md-6'>
                  <Controller
                    name={'enableMails'}
                    control={control}
                    render={({ field, fieldState }) => (
                      <DoRadioButtons
                        input={field}
                        id={field.id}
                        name={field.name}
                        field={field}
                        options={enableMailOptions}
                        fieldState={fieldState}
                        errors={errors}
                        label={'Enable Mails'}
                      />)}
                  />
                </div>

              </div>
              {watchEmailSourceType === 'sendgrid' &&
                <div className='d-flex col-6 gap-2'>
                  <div className='col-md-6'>
                    <Controller
                      name={'sendGridApiKey'}
                      control={control}
                      render={({ field, fieldState }) => (
                        <DoInputField
                          input={field}
                          id={field.id}
                          name={field.name}
                          label={'Send Grid Api Key'}
                          field={field}
                          type={"text"}
                          fieldState={fieldState}
                          errors={errors}
                          placeholder={'Send Grid Api Key'}
                        />
                      )}
                    />
                  </div>
                  <div className='col-md-6'>
                    <Controller
                      name={'sendGridEmail'}
                      control={control}
                      render={({ field, fieldState }) => (
                        <DoInputField
                          input={field}
                          id={field.id}
                          name={field.name}
                          label={'Send Grid Email'}
                          field={field}
                          type={"text"}
                          fieldState={fieldState}
                          errors={errors}
                          placeholder={'Send Grid Email'}
                        />
                      )}
                    />
                  </div>
                </div>
              }
            </div>

            {watchEmailSourceType === 'nodeMailer' &&
              <div className='row row-cols-4 mt-4'>
                <Controller
                  name={'nodeMailerHost'}
                  control={control}
                  render={({ field, fieldState }) => (
                    <DoInputField
                      input={field}
                      id={field.id}
                      name={field.name}
                      label={'Smtp host'}
                      field={field}
                      type={"text"}
                      fieldState={fieldState}
                      errors={errors}
                      placeholder={'Smtp Host'}
                    />)}
                />
                <Controller
                  name={'nodeMailerUser'}
                  control={control}
                  render={({ field, fieldState }) => (
                    <DoInputField
                      input={field}
                      id={field.id}
                      name={field.name}
                      label={'SMTP user'}
                      field={field}
                      type={"text"}
                      fieldState={fieldState}
                      errors={errors}
                      placeholder={'SMTP user'}
                    />)}
                />
                <Controller
                  name={'nodeMailerPass'}
                  control={control}
                  render={({ field, fieldState }) => (
                    <DoInputField
                      input={field}
                      id={field.id}
                      name={field.name}
                      label={'SMTP password'}
                      field={field}
                      type={"text"}
                      fieldState={fieldState}
                      errors={errors}
                      placeholder={'SMTP password'}
                    />)}
                />
                <Controller
                  name={'sendGridEmail'}
                  control={control}
                  render={({ field, fieldState }) => (
                    <DoInputField
                      input={field}
                      id={field.id}
                      name={field.name}
                      label={'Nodemailer Email'}
                      field={field}
                      type={"text"}
                      fieldState={fieldState}
                      errors={errors}
                      placeholder={'Nodemailer Email'}
                    />)}
                />
              </div>
            }
          </Card>

          <Card title='Token Time Settings' className='mt-3'>
            <div className='row row-cols-4'>
              <Controller
                name={'expireTokenTimeInMin'}
                control={control}
                render={({ field, fieldState }) => (
                  <DoInputField
                    input={field}
                    id={field.id}
                    name={field.name}
                    label={'Expire Token Time (min)'}
                    field={field}
                    type={"text"}
                    fieldState={fieldState}
                    errors={errors}
                    placeholder={'Expire Token Time'}
                  />)}
              />
              <Controller
                name={'adminExpireTokenTimeInMin'}
                control={control}
                render={({ field, fieldState }) => (
                  <DoInputField
                    input={field}
                    id={field.id}
                    name={field.name}
                    label={'Admin Expire Token Time (min)'}
                    field={field}
                    type={"text"}
                    fieldState={fieldState}
                    errors={errors}
                    placeholder={'Admin Expire Token Time'}
                  />)}
              />
              <div>
                <div className='d-flex'>
                  <Controller
                    name={'disableMultipleLogin'}
                    control={control}
                    render={({ field, fieldState }) => (
                      <DoRadioButtons
                        input={field}
                        id={field.id}
                        name={field.name}
                        field={field}
                        options={disableLoginOpt}
                        fieldState={fieldState}
                        errors={errors}
                        label={"Disable Multiple Login"}
                      />)}
                  />
                </div>
              </div>
            </div>
          </Card>

          <div className='d-flex gap-3'>
            <Card title='Google Settings' className='mt-3 col-6'>
              <div className='row row-cols-2'>
                <Controller
                  name={'GoogleClientId'}
                  control={control}
                  render={({ field, fieldState }) => (
                    <DoInputField
                      input={field}
                      id={field.id}
                      name={field.name}
                      label={'Admin Google Client ID(Login)'}
                      field={field}
                      type={"text"}
                      fieldState={fieldState}
                      errors={errors}
                      placeholder={'Admin Google Client ID'}
                    />)}
                />
                <Controller
                  name={'GooglesecretKey'}
                  control={control}
                  render={({ field, fieldState }) => (
                    <DoInputField
                      input={field}
                      id={field.id}
                      name={field.name}
                      label={'Admin Google Secret Key(Recaptcha)'}
                      field={field}
                      type={"text"}
                      fieldState={fieldState}
                      errors={errors}
                      placeholder={'Admin Google Secret Key'}
                    />)}
                />
              </div>
            </Card>
            <Card title='Two-Factor Authentication Settings' className='mt-3' style={{ width: '50%' }}>
              <div className='row row-cols-2'>
                <Controller
                  name={'isTwoFactorAuthentication'}
                  control={control}
                  render={({ field, fieldState }) => (
                    <DoRadioButtons
                      input={field}
                      id={field.id}
                      name={field.name}
                      field={field}
                      options={VerificationOptions}
                      fieldState={fieldState}
                      errors={errors}
                      label={'TwoFactor Authentication Required'}
                    />)}
                />
                <Controller
                  name={'otpExpiryTimeInMin'}
                  control={control}
                  render={({ field, fieldState }) => (
                    <DoInputField
                      input={field}
                      id={field.id}
                      name={field.name}
                      label={'Resend OTP Time(min)'}
                      field={field}
                      type={"text"}
                      fieldState={fieldState}
                      errors={errors}
                      placeholder={'OTP Time'}
                    />)}
                />
              </div>
            </Card>
          </div>

          <Card className="mt-3">
            <div className="p-card-title d-flex justify-content-between align-items-center pb-3">
              <span>Graph Configurations</span>
              <Button size="small" onClick={(e) => { e.preventDefault(); setDisplayInputField(true); }}>
                <FontAwesomeIcon icon="plus" size="lg" title="Add new Title" />
              </Button>
            </div>

            <div className="pb-3">
              <h4 className="fs-5 fw-bold text-dark mb-2">Time Configurations</h4>
              <div className="d-flex align-items-center gap-3">
                <span className="subheader">Time</span>
                <Controller
                  name="captureTime"
                  control={control}
                  render={({ field, fieldState }) => (
                    <DoInputField
                      {...field}
                      type="number"
                      fieldState={fieldState}
                      errors={errors}
                      placeholder="Capture Time (Min)"
                    />
                  )}
                />
              </div>
            </div>

            <div>
              <h4 className="fs-5 fw-bold text-dark mb-2">Color Configurations</h4>
              <div className='d-flex gap-5'>
                <div className={`row color-grid ${displayInputField ? 'col-8' : 'col-12'} `} >
                  {Object.keys(windows).map((window, index) => (
                    <div className={`  ${displayInputField ? 'col-4' : 'col-3'} d-flex justify-content-between align-items-center mb-2`} key={index}>
                      <div className="me-3">
                        {editingWindow === window ? (
                          <Controller
                            name="editWindow"
                            control={control}
                            defaultValue={editedValue}
                            render={({ field, fieldState }) => (
                              <DoInputField
                                input={field}
                                id={field.id}
                                name={field.name}
                                field={field}
                                type="text"
                                fieldState={fieldState}
                                errors={errors}
                                value={editedValue}
                                onChange={(e) => {
                                  field.onChange(e.target.value);
                                  setEditedValue(e.target.value);
                                }}
                                onBlur={() => handleSave(window)}
                                onKeyDown={(e) => handleKeyDown(e, window)}
                              />
                            )}
                          />
                        ) : (
                          <div className='text-capitalize' onClick={() => handleEditClick(window)}>{window}</div>
                        )}
                      </div>

                      <div className="d-flex align-items-center">
                        <button
                          type="button"
                          onClick={(e) => openColorPicker(e, window)}
                          style={{
                            backgroundColor: windows[window],
                            border: 'none',
                            padding: '10px 12px',
                            cursor: 'pointer',
                            color: '#fff',
                            marginRight: '5px',
                          }}
                        />

                        <FontAwesomeIcon
                          icon="times"
                          color="#3d3636"
                          title="Delete"
                          style={{ cursor: 'pointer', marginLeft: '10px' }}
                          onClick={() => { setIsOpenConfirmationModal(true); setDeleteWindow(window) }}
                        />
                      </div>
                    </div>
                  ))}

                  <div className="d-flex justify-content-end mt-1">
                    <Button color="primary" size="sm" type="submit">
                      Save
                    </Button>
                  </div>
                </div>

                <div className='col-3'>
                  {displayInputField && (
                    <div>
                      <label>Window Title</label>
                      <Controller
                        name="newWindow"
                        control={control}
                        render={({ field, fieldState }) => (
                          <>
                            <DoInputField
                              input={field}
                              id={field.id}
                              name={field.name}
                              type="text"
                              field={field}
                              fieldState={fieldState}
                              errors={errors}
                              placeholder="Window Title"
                              value={newWindow}
                              onChange={(e) => setNewWindow(e.target.value)}
                            />                            
                          </>
                        )}
                      />

                      <label className='pt-3'>Select Color</label>
                      <button
                        type="button"
                        onClick={(e) => op7.current.toggle(e)}
                        className="btn btn-outline-secondary d-flex flex-column px-5 py-2"
                        style={{ backgroundColor }}
                      >
                        Select Color
                      </button>

                      <div className="d-flex justify-content-end align-items-center gap-2">
                        <Button color="primary" size="sm" onClick={() => setDisplayInputField(false)}>
                          Close
                        </Button>

                        <Button color="primary" size="sm" type="button" onClick={() => updateWindows(newWindow)} >
                          Add
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <OverlayPanel className="colorPickers" ref={op7} showCloseIcon>
              <SketchPicker color={currentColor} onChange={onColorChange} />
            </OverlayPanel>

          </Card>

          <div className='d-flex mt-5 mb-5 justify-content-end'>
            <Button color='primary' size="sm" type='submit'>Update</Button>
          </div>

        </div>
      </form>

      {isOpenConfirmationModal &&
        <ConfirmationModal
          openConfirmationModal={isOpenConfirmationModal}
          closeConfirmationModal={closeModal}
          confirm={onConfirmation}
          text={'Delete the Titles'}
        />
      }

    </div>
  );
}
export default SettingsForm;